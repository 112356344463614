<template>
  <v-container
    class="my-6 mx-auto"
    style="max-width: 100% !important; background-color: white !important"
  >
    <h2 class="mx-4">
      {{ todayDateYear }}/{{ todayDateMonth }}/{{ todayDateDay
      }} 
    </h2>
    <v-card class="px-4 py-5">
      <v-row justify="end" no-gutters class="mr-n1 mb-6 mt-n12">
        <small
          @click="selectedMonth = month"
          small
          style="cursor: pointer; background: #dadada; color: #000 !important"
          class="border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1"
          :class="
            selectedMonth == month ? 'bg-gradient-warning white--text' : ''
          "
          :style="selectedMonth == month ? 'color: white' : ''"
          elevation="0"
          :ripple="false"
          v-for="(month, mindex) in months"
          :key="'month-' + mindex"
          >{{ month }}</small
        >
      </v-row>
      <v-data-table
        class="irzOverviewTable"
        v-if="students && loading == false"
        :headers="headers"
        :items="students"
        hide-default-header
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th class="text-start px-1">No</th>
              <th class="pl-2">Нэр</th>
              <th>Ирсэн</th>
              <th>Тасалсан</th>
              <th>Өвчтэй</th>
              <th>Чөлөөтэй</th>
              <th
                v-for="(day, dayIndex) in days"
                :key="
                  'header' +
                  $attrs.currentSelectedSemester +
                  'days' +
                  dayIndex +
                  selectedMonth +
                  day
                "
                class="text-center px-0 blue--text"
                style="
                  padding-left: 4px !important;
                  padding-right: 2px !important;
                "
              >
                {{ selectedMonth }}/{{ day }}
              </th>
            </tr>
          </thead>
        </template>
        <template slot="item" slot-scope="props">
          <tr>
            <td class="text-center px-1" style="width: 1%">
              {{ props.index + 1 }}
            </td>
            <td class="pl-2" style="width: 30%">
              {{ props.item["FIRST_NAME"] }},
              <span style="color: #a3a3a3">{{ props.item["LAST_NAME"] }}</span>
            </td>
            <td style="background-color: #f9fff2">
              <strong
                v-if="
                  props.item.absentNumber > 0 ||
                  props.item.sickNumber > 0 ||
                  props.item.excusedNumber
                "
                >{{
                  days.length -
                  props.item.absentNumber -
                  props.item.sickNumber -
                  props.item.excusedNumber
                }}</strong
              >
              <span v-else style="color: grey;">{{
                days.length -
                props.item.absentNumber -
                props.item.sickNumber -
                props.item.excusedNumber
              }}</span>
            </td>
            <td
              class="text-center"
              v-if="props.item.absentNumber > 0"
              style="width: 5%; background-color: red; color: white"
            >
              <span>{{ props.item.absentNumber }}</span>
            </td>
            <td v-else></td>

            <td
              class="text-center"
              v-if="props.item.sickNumber > 0"
              style="width: 5%; background-color: #ffa500; color: white"
            >
              <span>{{ props.item.sickNumber }}</span>
            </td>
            <td v-else></td>

            <td
              class="text-center"
              v-if="props.item.excusedNumber > 0"
              style="width: 5%; background-color: yellow"
            >
              <span>{{ props.item.excusedNumber }}</span>
            </td>
            <td v-else></td>

            <td
              v-for="(day, dayIndex) in days"
              :key="
                'body' +
                $attrs.currentSelectedSemester +
                'days' +
                dayIndex +
                selectedMonth +
                day
              "
              class="text-center px-1 text-lowercase"
              :style="
                getStudentAttendance(props.item, day) == 'И'
                  ? 'background:#f9fff2; color: grey !important'
                  : getStudentAttendance(props.item, day) == 'Тодорxойгүй' //Тодорxойгүй
                  ? 'background:#bbb'
                  : getStudentAttendance(props.item, day) == 'Xоцорсон' //Xоцорсон
                  ? 'background:#87B3FC'
                  : getStudentAttendance(props.item, day) == 'Чөлөөтэй' //Чөлөөтэй
                  ? 'background:yellow; color:black!important'
                  : getStudentAttendance(props.item, day) == 'Өвчтэй' //Өвчтэй
                  ? 'background:#FFA500; color:white'
                  : getStudentAttendance(props.item, day) == 'Тасалсан' //Тасалсан
                  ? 'background:red; color:white'
                  : ''
              "
            >
              {{ _getChar(getStudentAttendance(props.item, day)) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <h1
        v-else-if="(!students || students.length == 0) && loading == false"
        class="text-center"
      >
        Сурагчгүй
      </h1>
      <v-progress-linear v-else indeterminate color="red"></v-progress-linear>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);
// const fb = require("@/firebaseConfig.js");

export default {
  data: () => ({
    allAttendances: null,
    startSemesterMonth: null,
    endSemesterMonth: null,
    startSemesterDay: null,
    endSemesterDay: null,

    todayDateYear: null,
    todayDateMonth: null,
    todayDateDay: null,
    loading: true,
    months: [1, 2, 3, 4, 5, 6, 9, 10, 11, 12],
    days: null,
    students: null,
    selectedYear: null,
    selectedMonth: null,
    headers: [
      {
        text: "No",
      },
      {
        text: "Name",
      },
    ],
    noStudents: false,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    calendarButez() {
      return this.$store.state.calendarButez2;
    },
  },
  props: {
    currentClassGroup: {
      required: true,
      type: Object,
    },
  },
  watch: {
    selectedMonth(val) {
      this.days = [];
      for (const ss of this.calendarButez) {
        for (const mm of ss.months) {
          if (mm.name == val) {
            for (const dd of mm.days) {
              dd.month = mm.name;
              if (
                !dd.isHoliday &&
                this._isNotFuture(dd.year, dd.month, dd.day)
              ) {
                this.days.push(dd.day);
              }
            }
          }
        }
      }
      this.getAttendances();
      setTimeout(() => {
        if (this.students.length == 0) {
          this.noStudents = true;
        }
      }, 3000);
    },
  },
  methods: {
    _isNotFuture(year, month, day) {
      var ok = false;
      var checkingDate = new Date(year, month - 1, day);
      var todayDate = new Date();
      if (checkingDate < todayDate) {
        ok = true;
      } else {
        ok = false;
      }
      return ok;
    },
    _getChar(text) {
      return text.charAt(0);
    },
    _getNumbers(attendances, stat) {
      var xx = attendances.filter((att) => att.status == stat);
      if (xx.length == 0) return null;
      else return xx.length;
    },
    getStudentAttendance(student, day) {
      var attendance = this.allAttendances.find(
        (att) => att.studentId == String(student["PERSON_ID"]) && att.day == day
      );
      if (attendance) {
        return attendance.text;
      } else {
        return "И";
      }
    },
    async getAttendances() {
      this.allAttendances = [];
      this.loading = true;
      this.currentClassGroup.ref
        .collection("attendances-" + this.userData.school.currentYear)
        .where("year", "==", this.selectedYear)
        .where("month", "==", this.selectedMonth)
        .where("daily_attendance", "==", true)
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let attendance = doc.data();
            attendance.ref = doc.ref;
            attendance.id = doc.id;
            // student.attendances.push(attendance)
            this.allAttendances.push(attendance);
          });
          for (const student of this.students) {
            student.sickNumber = 0;
            student.absentNumber = 0;
            student.excusedNumber = 0;
            for (const att of this.allAttendances) {
              if (att.studentId == String(student.PERSON_ID)) {
                // if (!student.attendances) student.attendances = [];
                if (att.status == "-2") student.excusedNumber++;
                else if (att.status == "-3") student.sickNumber++;
                else if (att.status == "-4") student.absentNumber++;

                // student.attendances.push(att);
              }
            }
          }
          this.loading = false;
        });
    },
    async getClassStudents(classGroup) {
      var docs = await this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", classGroup.STUDENT_GROUP_ID)
        .orderBy("FIRST_NAME", "asc")
        .get();
      docs.forEach((doc) => {
        let student = doc.data();
        student.id = doc.id;
        student.ref = doc.ref;
        student.attendances = [];
        if (student.moved != true) {
          this.students.push(student);
        }
      });
    },
  },
  async created() {
    this.todayDateYear = new Date().getFullYear();
    this.todayDateMonth = new Date().getMonth() + 1;
    this.todayDateDay = new Date().getDate();

    this.selectedYear = new Date().getFullYear();
    this.selectedMonth = new Date().getMonth() + 1;
    // if (this.selectedMonth == 7) {
    //   this.selectedMonth = 9;
    // } else if (this.selectedMonth == 8) {
    //   this.selectedMonth = 9;
    // }
    this.students = [];
    this.loading = true;
    await this.getClassStudents(this.currentClassGroup);
    this.getAttendances();
    setTimeout(() => {
      if (this.students.length == 0) {
        this.noStudents = true;
      }
    }, 3000);
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.irzOverviewTable {
  border-collapse: collapse;
}

.irzOverviewTable td {
  border: 1px solid #dddddd;
  text-align: left;
  border: 1px solid #ddd;
  height: 1px !important;
}

.irzOverviewTable th {
  border: 1px solid #dddddd;
  height: 5px !important;
}

.fixed-column {
  position: sticky;
  left: 10px;
  z-index: 1;
  /* background-color: #ececec !important */
  /* Change this to your desired background color */
}
</style>
