var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"my-6 mx-auto",staticStyle:{"max-width":"100% !important","background-color":"white !important"}},[_c('h2',{staticClass:"mx-4"},[_vm._v(" "+_vm._s(_vm.todayDateYear)+"/"+_vm._s(_vm.todayDateMonth)+"/"+_vm._s(_vm.todayDateDay)+" ")]),_c('v-card',{staticClass:"px-4 py-5"},[_c('v-row',{staticClass:"mr-n1 mb-6 mt-n12",attrs:{"justify":"end","no-gutters":""}},_vm._l((_vm.months),function(month,mindex){return _c('small',{key:'month-' + mindex,staticClass:"border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1",class:_vm.selectedMonth == month ? 'bg-gradient-warning white--text' : '',staticStyle:{"cursor":"pointer","background":"#dadada","color":"#000 !important"},style:(_vm.selectedMonth == month ? 'color: white' : ''),attrs:{"small":"","elevation":"0","ripple":false},on:{"click":function($event){_vm.selectedMonth = month}}},[_vm._v(_vm._s(month))])}),0),(_vm.students && _vm.loading == false)?_c('v-data-table',{staticClass:"irzOverviewTable",attrs:{"headers":_vm.headers,"items":_vm.students,"hide-default-header":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"header",fn:function({ props: {} }){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-start px-1"},[_vm._v("No")]),_c('th',{staticClass:"pl-2"},[_vm._v("Нэр")]),_c('th',[_vm._v("Ирсэн")]),_c('th',[_vm._v("Тасалсан")]),_c('th',[_vm._v("Өвчтэй")]),_c('th',[_vm._v("Чөлөөтэй")]),_vm._l((_vm.days),function(day,dayIndex){return _c('th',{key:'header' +
                _vm.$attrs.currentSelectedSemester +
                'days' +
                dayIndex +
                _vm.selectedMonth +
                day,staticClass:"text-center px-0 blue--text",staticStyle:{"padding-left":"4px !important","padding-right":"2px !important"}},[_vm._v(" "+_vm._s(_vm.selectedMonth)+"/"+_vm._s(day)+" ")])})],2)])]}},{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-center px-1",staticStyle:{"width":"1%"}},[_vm._v(" "+_vm._s(props.index + 1)+" ")]),_c('td',{staticClass:"pl-2",staticStyle:{"width":"30%"}},[_vm._v(" "+_vm._s(props.item["FIRST_NAME"])+", "),_c('span',{staticStyle:{"color":"#a3a3a3"}},[_vm._v(_vm._s(props.item["LAST_NAME"]))])]),_c('td',{staticStyle:{"background-color":"#f9fff2"}},[(
                props.item.absentNumber > 0 ||
                props.item.sickNumber > 0 ||
                props.item.excusedNumber
              )?_c('strong',[_vm._v(_vm._s(_vm.days.length - props.item.absentNumber - props.item.sickNumber - props.item.excusedNumber))]):_c('span',{staticStyle:{"color":"grey"}},[_vm._v(_vm._s(_vm.days.length - props.item.absentNumber - props.item.sickNumber - props.item.excusedNumber))])]),(props.item.absentNumber > 0)?_c('td',{staticClass:"text-center",staticStyle:{"width":"5%","background-color":"red","color":"white"}},[_c('span',[_vm._v(_vm._s(props.item.absentNumber))])]):_c('td'),(props.item.sickNumber > 0)?_c('td',{staticClass:"text-center",staticStyle:{"width":"5%","background-color":"#ffa500","color":"white"}},[_c('span',[_vm._v(_vm._s(props.item.sickNumber))])]):_c('td'),(props.item.excusedNumber > 0)?_c('td',{staticClass:"text-center",staticStyle:{"width":"5%","background-color":"yellow"}},[_c('span',[_vm._v(_vm._s(props.item.excusedNumber))])]):_c('td'),_vm._l((_vm.days),function(day,dayIndex){return _c('td',{key:'body' +
              _vm.$attrs.currentSelectedSemester +
              'days' +
              dayIndex +
              _vm.selectedMonth +
              day,staticClass:"text-center px-1 text-lowercase",style:(_vm.getStudentAttendance(props.item, day) == 'И'
                ? 'background:#f9fff2; color: grey !important'
                : _vm.getStudentAttendance(props.item, day) == 'Тодорxойгүй' //Тодорxойгүй
                ? 'background:#bbb'
                : _vm.getStudentAttendance(props.item, day) == 'Xоцорсон' //Xоцорсон
                ? 'background:#87B3FC'
                : _vm.getStudentAttendance(props.item, day) == 'Чөлөөтэй' //Чөлөөтэй
                ? 'background:yellow; color:black!important'
                : _vm.getStudentAttendance(props.item, day) == 'Өвчтэй' //Өвчтэй
                ? 'background:#FFA500; color:white'
                : _vm.getStudentAttendance(props.item, day) == 'Тасалсан' //Тасалсан
                ? 'background:red; color:white'
                : '')},[_vm._v(" "+_vm._s(_vm._getChar(_vm.getStudentAttendance(props.item, day)))+" ")])})],2)]}}],null,false,920356820)}):((!_vm.students || _vm.students.length == 0) && _vm.loading == false)?_c('h1',{staticClass:"text-center"},[_vm._v(" Сурагчгүй ")]):_c('v-progress-linear',{attrs:{"indeterminate":"","color":"red"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }